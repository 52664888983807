import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M226.915 43.95c-1.081-.433-2.439-.98-4.035-1.625C202.842 34.236 145.278 11 128.062 11c-17.591 0-77.3 24.035-96.07 31.59l-2.784 1.12C23.576 46.133 14 54.613 14 65.796v121.333c0 10.344 8.167 22.449 18.025 26.28 2.5.971 5.172 2.015 7.978 3.111C69.217 227.934 112.9 245 128.062 245c15.16 0 58.844-17.046 88.058-28.446 2.806-1.094 5.478-2.137 7.979-3.108 9.857-3.825 18.024-15.916 18.024-26.248V66.01c0-11.17-9.575-19.64-15.208-22.06Zm-49.717 61.715c16.321 10.418 16.321 34.252 0 44.67l-52.444 33.476C107.116 195.07 84 182.401 84 161.476V94.524C84 73.6 107.116 60.93 124.754 72.19l52.444 33.476Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
