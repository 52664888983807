import NavBar, { NAV_BAR_HEIGHT } from "../components/NavBar";
import { Layout, LayoutContent } from "../components/Layout";
import styled from "styled-components";

export default ({ children }) => {
  return (
    <StyledLayout>
      <StyledLayoutContent>{children}</StyledLayoutContent>
      <NavBar />
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  padding-bottom: calc(${NAV_BAR_HEIGHT} + ${(p) => p.theme.padding.outer});
`;

const StyledLayoutContent = styled(LayoutContent)`
  position: relative;
`;
