import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M146.242 22.376c-10.764-8.244-25.72-8.244-36.484 0l-74 56.681A30 30 0 0 0 24 102.874V209c0 16.569 13.431 30 30 30h148c16.569 0 30-13.431 30-30V102.874c0-9.336-4.346-18.14-11.758-23.817l-74-56.68ZM163 187c0-5.523-4.477-10-10-10h-50c-5.523 0-10 4.477-10 10s4.477 10 10 10h50c5.523 0 10-4.477 10-10Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
