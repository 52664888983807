import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <path
      stroke="currentColor"
      strokeWidth={20}
      d="M34 209V102.874a20 20 0 0 1 7.839-15.878l73.999-56.68a20.001 20.001 0 0 1 24.324 0l74 56.68A20 20 0 0 1 222 102.874V209c0 11.046-8.954 20-20 20H54c-11.046 0-20-8.954-20-20Z"
    />
    <rect width={20} height={70} x={163} y={177} fill="currentColor" rx={10} transform="rotate(90 163 177)" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
