import { usePageContext } from "vike-react/usePageContext";
import Link from "./Link";
import { t } from "~/lang";
import styled, { ThemeProvider } from "styled-components";
import HomeStroke from "./icons/HomeStroke";
import HomeFill from "./icons/HomeFill";
import ControllerStroke from "./icons/ControllerStroke";
import ControllerFill from "./icons/ControllerFill";
import ShortsStroke from "./icons/ShortsStroke";
import ShortsFill from "./icons/ShortsFill";
import { useInterfaceStore } from "../stores/useInterfaceStore";
import themeLight from "../styles/variables/light";
import themeDark from "../styles/variables/dark";
import Text from "./copys/Text";

export default () => {
  const pageContext = usePageContext();
  const { urlPathname, locale } = pageContext;
  const variant = useInterfaceStore((state) => state.navBarVariant);

  const urlPathnameWithoutLocale = urlPathname.replace(`/${locale}`, "") || "/";
  const isActive = (to) => (to === "/" ? urlPathnameWithoutLocale === to : urlPathnameWithoutLocale.startsWith(to));

  return (
    <ThemeProvider theme={variant === "dark" ? themeDark : variant === "light" ? themeLight : {}}>
      <Wrapper>
        <Content>
          <Item $active={isActive("/shorts")} to="/shorts">
            <Icon>{isActive("/shorts") ? <ShortsFill /> : <ShortsStroke />}</Icon>
            <StyledText size="sm">{t("shorts")}</StyledText>
          </Item>
          <Item $active={isActive("/")} to="/">
            <Icon>{isActive("/") ? <HomeFill /> : <HomeStroke />}</Icon>
            <StyledText size="sm">{t("home")}</StyledText>
          </Item>
          <Item $active={isActive("/games")} to="/games">
            <Icon>{isActive("/games") ? <ControllerFill /> : <ControllerStroke />}</Icon>
            <StyledText size="sm">{t("games")}</StyledText>
          </Item>
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
};

export const NAV_BAR_HEIGHT = "3rem";

const Wrapper = styled.div`
  height: ${NAV_BAR_HEIGHT};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background: ${(p) => p.theme.background};
`;

const Content = styled.div`
  width: ${(p) => p.theme.maxWidth};
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

const Item = styled(Link)`
  display: flex;
  justify-content: center;
  color: ${(p) => p.theme.text[p.$active ? 400 : 500]};
  transition: color 0.125s;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 4rem;
  height: 100%;
`;

const Icon = styled.div`
  width: 1.5rem;
`;

const StyledText = styled(Text)`
  color: currentColor;
`;
