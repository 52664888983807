import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.859 94.695C16.509 61.849 44.27 37 77.319 37h101.01c33.047 0 60.81 24.85 64.46 57.695l7.537 67.835c3.407 30.652-20.588 57.46-51.427 57.46a51.746 51.746 0 0 1-46.282-28.605l-3.808-7.616h-41.97l-3.808 7.616A51.745 51.745 0 0 1 56.75 219.99c-30.84 0-54.834-26.808-51.429-57.46l7.538-67.835Zm67.427-12.418c5.522 0 10 4.477 10 10v11.129h11.129c5.523 0 10 4.477 10 10s-4.477 10-10 10h-11.13v11.127c0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10v-11.127H59.16c-5.523 0-10-4.477-10-10s4.477-10 10-10h11.127v-11.13c0-5.522 4.477-10 10-10Zm96.396 14.527c0-8.02 6.5-14.527 14.526-14.527 8.025 0 14.527 6.505 14.527 14.527 0 8.022-6.502 14.528-14.527 14.528-8.027 0-14.526-6.508-14.526-14.528Zm-27.16 27.497c.176 7.869 6.611 14.195 14.523 14.195 8.024 0 14.528-6.505 14.528-14.528 0-.11-.002-.22-.006-.33-.176-7.87-6.61-14.196-14.523-14.196-8.024 0-14.528 6.505-14.528 14.527 0 .111.002.222.006.332Z"
      clipRule="evenodd"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
