import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={20}
      d="M80.285 92.277v42.256M101.415 113.406H59.159"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={20}
      d="m22.797 95.8-7.537 67.834c-2.747 24.729 16.61 46.356 41.49 46.356a41.747 41.747 0 0 0 37.337-23.077l6.572-13.144h54.33l6.572 13.144a41.747 41.747 0 0 0 37.338 23.077c24.879 0 44.237-21.627 41.488-46.356l-7.537-67.835C229.763 68.018 206.281 47 178.329 47H77.319c-27.952 0-51.435 21.018-54.522 48.8Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={20}
      d="M191.208 101.332a4.527 4.527 0 0 1-4.526-4.528c0-2.5 2.026-4.527 4.526-4.527M191.208 101.332a4.527 4.527 0 1 0 0-9.055M159.516 123.969a4.527 4.527 0 0 1 9.055 0"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={20}
      d="M159.518 123.968a4.527 4.527 0 1 0 9.055 0"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
