import { forwardRef, memo } from "react";

const SvgComponent = (props, ref) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="none" ref={ref} {...props}>
    <path
      stroke="currentColor"
      strokeWidth={20}
      d="M33.077 52.932c.762-.305 1.651-.663 2.655-1.067 8.865-3.569 26.657-10.73 44.829-17.31 10.188-3.688 20.361-7.144 29.057-9.665 9.084-2.634 15.398-3.89 18.444-3.89 3.031 0 9.334 1.264 18.42 3.922 8.695 2.543 18.867 6.029 29.056 9.748 17.347 6.332 34.328 13.186 43.57 16.916 1.54.622 2.866 1.157 3.942 1.588 1.218.55 3.685 2.2 5.818 4.828 2.112 2.601 3.255 5.356 3.255 8.008v121.188c0 2.695-1.144 6.369-3.573 9.923-2.423 3.547-5.45 5.986-8.069 7.003-2.532.982-5.227 2.034-8.049 3.135-13.205 5.153-29.18 11.386-44.161 16.691-9.085 3.218-17.647 6.037-24.877 8.04-7.523 2.085-12.663 3.01-15.332 3.01-2.668 0-7.807-.926-15.33-3.013-7.229-2.005-15.792-4.828-24.876-8.049-14.98-5.312-30.956-11.553-44.161-16.712-2.822-1.102-5.517-2.155-8.048-3.138-2.619-1.018-5.646-3.46-8.07-7.013-2.43-3.56-3.577-7.242-3.577-9.946V65.796c0-2.663 1.147-5.425 3.26-8.03 2.132-2.63 4.6-4.284 5.817-4.834Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={20}
      d="M171.818 114.094c10.161 6.486 10.161 21.326 0 27.812l-52.444 33.476C108.392 182.391 94 174.504 94 161.476V94.524c0-13.028 14.392-20.915 25.374-13.906l52.444 33.476Z"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export default Memo;
